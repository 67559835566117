import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "general-settings"
    }}>{`General settings`}</h1>
    <p>{`You can customize the chat by inserting the script options below to Cobrowse.create function as parameters`}</p>
    <h4 {...{
      "id": "locale"
    }}>{`locale`}</h4>
    <p>{`You can set the locale of the chat `}<br />{`
`}<inlineCode parentName="p">{`type: String`}</inlineCode>{` `}<br />{`
`}<inlineCode parentName="p">{`value: 'fin'|'eng'|'swe'`}</inlineCode></p>
    <h4 {...{
      "id": "acceptallurls"
    }}>{`acceptAllUrls`}</h4>
    <p>{`By default chat converts only urls on your site to clickable, this options converts all of the links in chat to clickable urls `}<br />{`
`}<inlineCode parentName="p">{`type: Boolean`}</inlineCode></p>
    <h4 {...{
      "id": "eventreceiver"
    }}>{`eventReceiver`}</h4>
    <p>{`eventReceiver is used to push events to GTM Dataleyer `}<a parentName="p" {...{
        "href": "/google-tag-manager"
      }}>{`more info here`}</a></p>
    <h4 {...{
      "id": "context"
    }}>{`context`}</h4>
    <p>{`You can define the team where chat is routed by setting a value `}<br />{`
`}<inlineCode parentName="p">{`type: String`}</inlineCode>{` `}<br />{`
`}<inlineCode parentName="p">{`value: any string`}</inlineCode></p>
    <h2 {...{
      "id": "tab-behaviour"
    }}>{`Tab behaviour`}</h2>
    <h4 {...{
      "id": "hiddenwhennoagents"
    }}>{`hiddenWhenNoAgents`}</h4>
    <p>{`Chat tab can be hidden when no agents are available for chat
`}<inlineCode parentName="p">{`type: Boolean`}</inlineCode></p>
    <h4 {...{
      "id": "notab"
    }}>{`noTab`}</h4>
    <p>{`Chat tab can be disabled completely from rendering and chat can be launched with different means
`}<inlineCode parentName="p">{`type: Boolean`}</inlineCode></p>
    <h4 {...{
      "id": "tabcolor"
    }}>{`tabColor`}</h4>
    <h4 {...{
      "id": "tabcolormobile"
    }}>{`tabColorMobile`}</h4>
    <h4 {...{
      "id": "tabtextcolor"
    }}>{`tabTextColor`}</h4>
    <h4 {...{
      "id": "tabtextcolormobile"
    }}>{`tabTextColorMobile`}</h4>
    <h4 {...{
      "id": "tablocation"
    }}>{`tabLocation`}</h4>
    <p>{`The location of the chat tab `}<br />{`
`}<inlineCode parentName="p">{`type: String`}</inlineCode>{` `}<br />{`
`}<inlineCode parentName="p">{`value: 'right', 'bottom-right', 'bottom-left'`}</inlineCode></p>
    <h4 {...{
      "id": "startbuttontitle"
    }}>{`startButtonTitle`}</h4>
    <h4 {...{
      "id": "startbuttonsymbol"
    }}>{`startButtonSymbol`}</h4>
    <h4 {...{
      "id": "offlinebuttontitle"
    }}>{`offlineButtonTitle`}</h4>
    <h4 {...{
      "id": "startbuttonsymbolmobile"
    }}>{`startButtonSymbolMobile`}</h4>
    <h4 {...{
      "id": "offlinebuttontitle-1"
    }}>{`offlineButtonTitle`}</h4>
    <h4 {...{
      "id": "offlinebuttonsymbol"
    }}>{`offlineButtonSymbol`}</h4>
    <h4 {...{
      "id": "offlinebuttonsymbolmobile"
    }}>{`offlineButtonSymbolMobile`}</h4>
    <h4 {...{
      "id": "offlinebuttontabcolor"
    }}>{`offlineButtonTabColor`}</h4>
    <h4 {...{
      "id": "offlinebuttonmobiletabcolor"
    }}>{`offlineButtonMobileTabColor`}</h4>
    <h4 {...{
      "id": "offlinebuttontextcolor"
    }}>{`offlineButtonTextColor`}</h4>
    <h4 {...{
      "id": "offlinebuttonmobiletextcolor"
    }}>{`offlineButtonMobileTextColor`}</h4>
    <h2 {...{
      "id": "chatbox-behaviour"
    }}>{`Chatbox behaviour`}</h2>
    <h4 {...{
      "id": "smallmobilechat"
    }}>{`smallMobileChat`}</h4>
    <p>{`You can have a smaller chat window in mobile view, default is fullscreen `}<br />{`
`}<inlineCode parentName="p">{`type: Boolean`}</inlineCode></p>
    <h4 {...{
      "id": "smalldesktopchat"
    }}>{`smallDesktopChat`}</h4>
    <p>{`You can have a smaller chat window in desktop view `}<br />{`
`}<inlineCode parentName="p">{`type: Boolean`}</inlineCode></p>
    <h4 {...{
      "id": "autopopupdelay"
    }}>{`autoPopupDelay`}</h4>
    <p>{`You can set the time for autoPopupDelay
`}<inlineCode parentName="p">{`type: Int`}</inlineCode>{`
`}<inlineCode parentName="p">{`value: any Int`}</inlineCode></p>
    <h4 {...{
      "id": "maxagentwaittime"
    }}>{`maxAgentWaitTime`}</h4>
    <p>{`You can set the max time that the customer will wait before moved to contact form after starting a chat
`}<inlineCode parentName="p">{`type: Int`}</inlineCode>{`
`}<inlineCode parentName="p">{`value: any Int`}</inlineCode></p>
    <h4 {...{
      "id": "useofflinebuttons"
    }}>{`useOfflineButtons`}</h4>
    <h4 {...{
      "id": "smileeassistant"
    }}>{`smileeAssistant`}</h4>
    <h4 {...{
      "id": "closechatcross"
    }}>{`closeChatCross`}</h4>
    <h4 {...{
      "id": "fontcolor"
    }}>{`fontColor`}</h4>
    <h4 {...{
      "id": "chatmaincolor"
    }}>{`chatMainColor`}</h4>
    <h4 {...{
      "id": "chatsecondarycolor"
    }}>{`chatSecondaryColor`}</h4>
    <h4 {...{
      "id": "backgroundcolor"
    }}>{`backgroundColor`}</h4>
    <h4 {...{
      "id": "dashbuttoncolor"
    }}>{`dashButtonColor`}</h4>
    <h4 {...{
      "id": "welcometitle"
    }}>{`welcomeTitle`}</h4>
    <h4 {...{
      "id": "welcometext"
    }}>{`welcomeText`}</h4>
    <h4 {...{
      "id": "waitingforagentmsg"
    }}>{`waitingForAgentMsg`}</h4>
    <h4 {...{
      "id": "waitingforagentbtn"
    }}>{`waitingForAgentBtn`}</h4>
    <h4 {...{
      "id": "feedbackbuttondelay"
    }}>{`feedbackButtonDelay`}</h4>
    <h4 {...{
      "id": "activecontactform"
    }}>{`activeContactForm`}</h4>
    <p>{`You can define the contactform to be used per site `}<br />{`
`}<inlineCode parentName="p">{`type: String`}</inlineCode>{` `}<br />{`
`}<inlineCode parentName="p">{`value: any string`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Cobrowse.create({
  apiKey: "LQt/yocAfcWRAt...",
  activeContactForm: "Just another form",
});
`}</code></pre>
    <h4 {...{
      "id": "useagentname"
    }}>{`useAgentName`}</h4>
    <h4 {...{
      "id": "useagentavatar"
    }}>{`useAgentAvatar`}</h4>
    <h4 {...{
      "id": "useclientavatar"
    }}>{`useClientAvatar`}</h4>
    <h4 {...{
      "id": "useclientname"
    }}>{`useClientName`}</h4>
    <h4 {...{
      "id": "fontsizeselector"
    }}>{`fontSizeSelector`}</h4>
    <h4 {...{
      "id": "chatsizeselector"
    }}>{`chatSizeSelector`}</h4>
    <h2 {...{
      "id": "beta"
    }}>{`BETA`}</h2>
    <p>{`These options are in beta and might have bugs or be removed in the future`}</p>
    <h4 {...{
      "id": "draggable-boolean"
    }}>{`draggable (Boolean)`}</h4>
    <p>{`You can make the chatbox draggable in the UI `}<br />{`
`}<inlineCode parentName="p">{`type`}</inlineCode>{`: Boolean`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      